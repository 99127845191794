.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: 120px;
  color: #95a2aa;
  line-height: 1.5;
}

.imageSize {
  width: 58px;
  height: 68px;
  cursor: pointer;
}

.viewPhoto {
  max-width: 700px;
  max-height: 500px;
  margin: auto;
}

.subBusinessId {
  margin-top: 5px;
  font-size: 11px;
  width: auto;
  color: #95a2aa;
  line-height: 1.5;
}

.detailPhotoContainer {
  width: 100%;
  height: 250px;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    position: relative;
  }
}

.rowDetails {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 12px;
  div:first-child {
    width: 120px;
  }
  div:last-child {
    width: 80%;
    word-wrap: break-word;
  }
  div {
    color: #95a2aa;
  }

  .columnDetails {
    display: flex;
    flex-direction: column;
    div {
      width: 100%;
    }
  }
}
