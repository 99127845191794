.status {
    padding: 10px 15px 10px 15px;
}

.td{
    color: black
}

.sync{
    font-weight: 600;
    .percentage{
        color: green;
    }
    .content {
        padding-left: 15px;
        color: black;
    }
    
}