.modal {
  background-color: transparent;
  width: 100% !important;
  min-width: 100% !important;
  height: 100% !important;
  text-align: center !important;
}

.viewPhoto {
  max-width: 700px;
}

.modalContent {
  text-align: center !important;
  display: flex;
  align-items: center;
  height: calc(100% - 50px) !important;
  width: 100% !important;
}

.container {
  // z-index: 111111111111111 !important;
  // padding-left: 0px !important;
  padding-top: 60px;
  background-color: #141b43ab !important;
}
