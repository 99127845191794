.container {
  width: 100%;
}

.disabled {
  :global {
    .MuiOutlinedInput-root {
      background: var(--color-concrete);
    }
    fieldset {
      border-color: var(--color-concrete) !important;
    }
    // input {
    //   color: var(--color-cement);
    // }
  }
}

.input {
  width: 100%;

  :global {
    .MuiOutlinedInput-root.MuiInputBase-formControl {
      height: 38px;
    }
    input {
      font-weight: 500;
      font-size: 14px;
    }
    fieldset {
      border-color: var(--color-ash);
    }
    .PrivateNotchedOutline-legendLabelled-3 {
      display: none !important;
    }
  }
}

.multiline {
  :global {
    .MuiOutlinedInput-root {
      width: 100%;
      display: block;
    }
    .MuiOutlinedInput-root.MuiInputBase-formControl {
      height: auto;
      min-height: 100px;
    }
    textarea {
      font-weight: 500;
      font-size: 14px;
      min-height: 60px !important;
      resize: vertical;
    }
    fieldset {
      border-color: var(--color-ash);
    }
  }
}

.loader {
  height: 20px !important;
  width: 20px !important;
}
